<template>
  <div>
    <div v-if="workshop.delivery_methods.length > 1">
      This is a <strong>Hybrid</strong> workshop and will be delivered in multiple formats.
    </div>
  <div v-for="(deliveryMethod, idx) in workshop.delivery_methods" style="margin-top: 1rem;">
    <div v-if="deliveryMethod == 'in_person'">
      <div style="margin-bottom: .5rem;" v-if="workshop.delivery_methods.length > 1"><strong>In Person:</strong></div>
      {{ workshop.implementation_site.name }}<br>
      {{ workshop.implementation_site.address.address }}<br>
      {{ workshop.implementation_site.address.city }}, {{ workshop.implementation_site.address.state }} {{ workshop.implementation_site.address.zip }}
    </div>
    <div v-else-if="deliveryMethod == 'virtual'">
      This workshop will
      <span v-if="idx > 0 && workshop.delivery_methods.length > 1">also</span> be delivered <strong>virtually online</strong> via <strong v-html="platform(workshop)"></strong>.
      The program coordinator (or leader) for the workshop will reach out to all participants with instructions on how to access the workshop sessions.
    </div>
    <div v-else-if="deliveryMethod == 'telephonic'">
      This workshop <span v-if="idx > 0 && workshop.delivery_methods.length > 1">also</span> will be delivered via <strong>telephone only</strong>.
      The program coordinator (or leader) for the workshop will reach out to all participants with instructions on how to access the workshop sessions.
    </div>
  </div>

  <div class="margin-top-20" v-if="workshop.implementation_site.accessible">
    <strong>This location is accessible.</strong> {{workshop.implementation_site.accessible_notes}}
  </div>
  </div>
</template>

<script>
export default {
  props: {
    workshop: {
      required: true,
      type: Object,
    }
  },
  methods: {
    platform: (workshop) => {
      return workshop.virtual_platform.value == 'other' ?
          workshop.virtual_platform_other :
          workshop.virtual_platform.value.replace(/\b[a-z]/g, function(letter) {
            return letter.toUpperCase();
          }).replace('_', ' ');
    }
  }
}
</script>