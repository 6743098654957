<template>
  <Teleport to="body" v-if="workshop != null" >
    <div class="compass-container"><!-- This is added again because we are attaching the modal to the body. -->
      <modal :z-offset="1000000" size="lg" v-model="opened" :title="workshop.local_program_name" @hide="closeCallback" ref="modal" id="modal-ws-info">
          <workshop-information :workshop="workshop" :show-enroll="showEnroll"></workshop-information>
          <div slot="header">
            <h4 class="modal-title">{{ workshop.local_program_name}}</h4>
          </div>

          <div slot="footer">
              <enroll-button size="lg" :workshop="workshop" v-if="showEnroll"/>
              <a href="#" v-on:click.prevent="opened = false" class="btn btn-danger btn-lg">Close</a>

          </div>
      </modal>
    </div>
  </Teleport>
</template>

<script>

import Teleport from 'vue2-teleport';
import { Modal } from 'uiv'
import EnrollButton from "./util/EnrollButton";
import WorkshopInformation from "./WorkshopInformation";

export default {
    components: {WorkshopInformation, EnrollButton, Modal, Teleport },

    props: {
        value: {
            default: null,
            required: true,
        },
        showEnroll: {
            default: true,
            type: Boolean,
            required: false,
        }

    },

    data () {
        return {
            opened: false,
            workshop: this.value,
        }
    },

    watch : {
        value: function(val) {
            this.workshop = val
            this.opened = val != null
        }
    },

    methods : {
        closeCallback ( ){
            this.$emit('input', null)
            this.workshop = null
        }
    }
}
</script>