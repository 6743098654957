<template>
  <div class="b3-row" v-if="!isLoading">
    <div class="b3-col-md-12">
      <div class="well align-left box-default margin-bottom">
        <h4 class="no-margin-top">Workshop Search</h4>
        <div class="b3-row">
          <div class="b3-col-md-12">
            <p>
              Search here to find and enroll in evidence-based health and wellness workshops being offered. Enter your
              zip code, find the workshop for you and register online!
            </p>
          </div>
        </div>
        <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="doSearch">
          <div class="b3-row flex-vert-bottom row-ws-search">
            <div class="form-group b3-col-md-3 v-top">
              <div>
                <label class="block block control-label" for="search_workshop_form_zipCode">Zip Code:</label>
                <span class="text-danger pull-right" v-if="!isValid">(Zip code must be 5 digits.)</span>
                <div class="input-group">
                  <ValidationProvider :rules="{ regex: /(^\d{5}$)/ }" v-slot="{ errors, classes }" >
                    <span :class="classes">
                    <input type="text" class="form-control input-small" v-model="searchData.zipCode"
                           placeholder="Enter Your Zip Code..." id="search_workshop_form_zipCode">
                      </span>
                  </ValidationProvider>
                  <span class="input-group-btn" style="width:0px;"></span>

                  <label class="sr-only" id="search_distance_label">Search Distance</label>
                  <select class="form-control" v-model="searchData.distance" id="search_workshop_form_distance" aria-labelledby="search_distance_label">
                    <option value="30">30 miles</option>
                    <option disabled="disabled">-------------------</option>
                    <option value="5">5 miles</option>
                    <option value="10">10 miles</option>
                    <option value="15">15 miles</option>
                    <option value="50">50 miles</option>
                    <option value="100">100 miles</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group b3-col-md-2">
              <label class="block block control-label" for="search_workshop_form_deliveryMethod">Delivery Method:</label>
              <select class="form-control" v-model="searchData.deliveryMethod" id="search_workshop_form_deliveryMethod">
                <option :value="null">All Modes</option>
                <option value="in_person">In-Person</option>
                <option value="virtual">Virtual/Online</option>
                <option value="telephonic">Telephonic</option>
                <option value="self_directed">Self-Directed</option>
              </select>
            </div>
            <div class="form-group b3-col-md-2" v-if="!ownerArgumentSet">
              <label class="block block control-label" for="search_workshop_form_stateId">State/Region:</label>
              <select class="form-control" v-model="searchData.stateId" id="search_workshop_form_stateId">
                <option value="">Narrow by State/Region:</option>
                <option v-for="(state, acronym) in states" v-bind:key="acronym" :value="acronym">{{
                    state
                  }}
                </option>
              </select>
            </div>

            <div class="form-group b3-col-md-3">
              <label class="block block control-label" for="search_workshop_form_programId">Program:</label>
              <program-dropdown v-model="searchData.programId" :use-groups="true"
                                id="search_workshop_form_programId"/>
            </div>
            <div class="form-group b3-col-md-3 mb-0">

              <button type="submit" style="margin-right: 5px;" class="btn btn-ar btn-success">Search!</button>
              <router-link :to="{ name: 'workshop_interest' }" class="btn btn-ar btn-info" v-if="ownerArgumentSet">
                Request Information
              </router-link>
            </div>
          </div>
          <div class="b3-row hidden-xs">
            <div class="b3-col-md-12">
              <p></p>
              <small class="text-muted" v-if="ownerArgumentSet">
                If you are unable to locate a workshop meeting your needs or would like more information about programs,
                please click on "Request Information" above and submit your request. We will make every effort to
                connect
                you to organizations delivering workshops near you.
              </small>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import {getStates} from "../api/api"
import ProgramDropdown from './fields/ProgramDropdown'
import {ValidationProvider} from "vee-validate";

export default {
  components: {ProgramDropdown, ValidationProvider},
  data() {
    return {
      isLoading: false,
      states: [],
      programs: [],
      isValid: true,
      searchData: {
        zipCode: this.$route.query.zipCode,
        distance: this.$route.query.distance || "30",
        owner: this.$route.query.owner || null,
        programId: this.$route.query.programId || null,
        stateId: this.$route.query.stateId || null,
        deliveryMethod: this.$route.query.deliveryMethod || null,
      },
      queryData: {}
    }
  },

  created: function () {
    Promise.all([getStates()]).then((values) => {
      this.states = values[0].data;
    })
  },

  methods: {
    async doSearch() {
      var search = this.searchData;
      this.isValid = await this.$refs.observer.validate();
      if (!this.isValid) {
        return;
      }
      for (var k in search) {
        if (!search[k]) {
          delete search[k]
        }
      }

      this.$router.push({name: 'find_a_workshop', query: search})
    }
  }
}

</script>