<template>
  <Teleport to="body" >
    <div class="compass-container"><!-- This is added again because we are attaching the modal to the body. -->
      <modal :z-offset="1000000" v-model="value" @hide="closeCallback" ref="modal" id="modal-ws-info">
        <p>
          Your workshop registration information submitted online will be maintained securely by the Find-a-Workshop vendor and will be shared with the workshop provider you choose.
        </p>
        <p>
          Your information may be shared with a State Department of Health or State Unit on Aging that licenses Find-a-Workshop to workshop providers in your community. Information that may be shared includes your name, address, telephone number and/or email and the workshop that you selected.
        </p>
        <p>Providers and licensees collect this data to connect you with a program in your community. </p>
        <p>Your information will be used:</p>
        <ul>
          <li>To enroll you in a workshop</li>
          <li>For program planning: using group enrollment numbers to know where new workshops are needed.</li>
          <li>The find-a-workshop vendor, licensees and providers will not share your personal identifiable information with any other organization, or any person outside the provider or licensee.</li>
        </ul>
        <p>If you are not comfortable sharing this information online, you will need to call the workshop provider directly to share your information. There is no penalty for choosing not to share your information online; however, you may not be able to participate in a workshop if you refuse to provide this information.</p>

          <div slot="header">
            <h4 class="modal-title">Data Sharing Agreement</h4>
          </div>

          <div slot="footer">
              <a href="#" v-on:click.prevent="value = false" class="btn btn-danger btn-lg">Close</a>

          </div>
      </modal>
    </div>
  </Teleport>
</template>

<script>

import Teleport from 'vue2-teleport';
import { Modal } from 'uiv'

export default {
  components: {Modal, Teleport },
    props: {
        value: {
            default: false,
            type: Boolean,
            required: true,
        },

    },

  data () {
    return {
      opened: true,

    }
  },

    methods : {
        closeCallback ( ){
            this.$emit('input', null)
            this.value = false
        }
    }
}
</script>