<template>
    <div>
        <div class="alert alert-success">You have successfully registered for this workshop.  Your workshop details are displayed below!</div>
        <workshop-information :workshop="workshop" v-if="!isLoading" :show-enroll="false"></workshop-information>

    </div>
</template>

<script>
import {getWorkshop} from '../api/api'

import NProgress from 'nprogress'
import WorkshopInformation from "./WorkshopInformation";

export default {
        components: {WorkshopInformation},

        data() {
            return {
                isLoading: true,
                workshop: {},
            }
        },

        created() {
            this.fetchData()
        },

        beforeRouteUpdate(to, from, next) {
            this.fetchData().then(() => next())
        },

        methods: {
            fetchData() {

                this.isLoading = true
                this.hasResults = true
                this.invalidQuery = false

                const params = this.$route.params
                return new Promise((resolve, reject) => {
                    NProgress.start()
                    getWorkshop(params.id).then((resp) => {
                        this.workshop = resp.data
                        this.isLoading = false
                        NProgress.done()
                        resolve()
                    }).catch(() => {
                        reject()
                        NProgress.done()
                    })
                })
            },
        }
    }

</script>