<template>
    <div id="compass-app" class="compass-container">
        <div class="row">
            <div class="col-sm-12">
                <router-view></router-view>
                <div style="text-align: right">
                    <small>Powered by</small>&nbsp;&nbsp;<a href="https://compasshp.org" target="_blank" rel="nofollow"><img
                        class="pull-right" src="./assets/title_dark.png" alt="Powered by Compass"/></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'app'
    }
</script>
