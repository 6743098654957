<style type="text/css" scoped>
.alert-danger {
  padding: 5px 10px !important;
}
</style>
<template>
  <validation-observer ref="form" tag="form" autocomplete="off" class="registration form-horizontal"
                       @submit.prevent="submitRegistration"
                       v-slot="{ invalid }">
    <div class="well align-left box-default head justify-between align-middle">
      <h3 class="header-title">Workshop Registration</h3>
      <div>
        <router-link v-if="showReturn" :to="{ name: 'find_a_workshop', query: $route.query }"
                     class="btn btn-danger">Return to Find-a-Workshop
        </router-link>
      </div>
    </div>

    <div class="b3-row" v-if="!isLoading && !notFound">
      <div class="b3-col-xs-12 b3-col-md-4">
        <div class="panel panel-default">
          <div class="panel-body border-bottom text-center">
            <h5 class="ws-name">{{ workshop.local_program_name }}</h5>
            <hr>
            <ul class="list-unstyled p-1 mb-0">
              <li v-if="workshop.session_zero_datetime" class="display-flex justify-between">
                <strong>Info Session:</strong>
                {{ workshop.session_zero_datetime | moment("dddd, MMM D, YYYY") }}
              </li>
              <li class="display-flex justify-between">
                <strong>Start Date:</strong>
                <workshop-start-date :workshop="workshop"></workshop-start-date>
              </li>
              <li class="display-flex justify-between mt-1">
                <strong>Language:</strong>
                {{ workshop.language.name }}
              </li>
            </ul>
            <hr>
            <a href="#" v-on:click.prevent="openWorkshopDetails" v-if="workshop" class="btn btn-info">Workshop
              Details<span class="hidden-md">&nbsp;and Schedule</span> </a>
          </div>
          <div class="panel-heading"><strong>Location</strong></div>
          <div class="panel-body">
            {{ workshop.implementation_site.name }}
            <workshop-location :workshop="workshop"/>
          </div>
          <div class="panel-heading hidden-xs hidden-sm border-top"><strong>Description</strong></div>
          <div class="panel-body hidden-xs hidden-sm paragraph">
            <div v-html="workshop.program.description"></div>
            <div v-if="workshop.description">
              <hr/>
              <div v-html="workshop.description"></div>
            </div>
          </div>

            <div class="panel-heading border-top"><strong>Contact Information</strong></div>
            <div class="panel-body">
                <ul class="list-unstyled p-1 mb-0">
                    <li v-if="workshop.contact_phone" class="display-flex justify-between">
                        <strong>Phone Number:</strong>
                        {{ workshop.contact_phone | phone }}
                    </li>
                    <li class="display-flex justify-between mt-1">
                        <strong>Email:</strong>
                        <a :href="'mailto:' +  workshop.contact_email">{{ workshop.contact_email }}</a>
                    </li>
                </ul>
            </div>
        </div>
      </div>
      <div class="b3-col-xs-12 b3-col-sm-12 b3-col-md-8">

        <div class="panel panel-default">
          <div class="panel-heading"><strong>Registration Form</strong></div>
          <div class="panel-body">
            <div id="workshop_reg_form">
              <div v-if="hasRegistrationMessage && registrationMessagePosition == 'top'">
                <small v-html="registrationMessage"></small>
                <hr>
              </div>
              <registration-name-and-contact :participant="participant" :require-address="requireAddress" :sms-enabled="workshop.smsEnabled"></registration-name-and-contact>
              <registration-language-referral :participant="participant"></registration-language-referral>
              <validation-provider v-if="askDob" mode="lazy" :rules="{ regex: /^(19\d\d|20\d\d)-(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/ }" v-slot="{ errors, classes }">
                <div class="form-group" :class="classes">
                  <label class="b3-col-sm-3 control-label required" for="workshop_reg_form_dob">
                    Date of Birth
                  </label>
                  <div class="b3-col-sm-6">
                    <input type="date" id="workshop_reg_form_dob"
                           v-model="participant.dob"
                           mode="lazy"
                           class="form-control">
                  </div>
                  <div v-show="errors.length" class="help-block b3-col-sm-9 b3-col-sm-offset-3">Please enter a valid date of birth (MM/DD/YYYY).</div>
                </div>

              </validation-provider>
              <validation-provider v-if="askAccommodation" rules="required" v-slot="{ errors, classes }">
                <div class="form-group required" :class="classes">
                  <label class="block control-label required b3-col-sm-offset-3 b3-col-sm-9" style="text-align: left;">
                    I would like to request accommodations for this workshop.
                  </label>
                  <div class="block b3-col-sm-offset-3 b3-col-sm-9">
                    <div id="participant_registration_requestAccommodation">
                      <div class="radio">
                        <label for="participant_registration_requestAccommodation_0">
                          <input type="radio"
                                 id="participant_registration_requestAccommodation_0"
                                 v-model="participant.accommodations.requestAccommodation"
                                 v-bind:value="true"/> Yes
                        </label></div>
                      <div class="radio">
                        <label for="participant_registration_requestAccommodation_1">
                          <input type="radio"
                                 id="participant_registration_requestAccommodation_1"
                                 v-model="participant.accommodations.requestAccommodation"
                                 v-bind:value="false"/> No
                        </label>
                      </div>
                    </div>
                    <div v-show="errors.length" class="help-block">*This field is required.</div>
                  </div>
                </div>
              </validation-provider>

              <validation-provider rules="required" v-slot="{ errors, classes }"
                                   v-if="participant.accommodations.requestAccommodation == 1 && askAccommodation">
                <div class="form-group required" :class="classes">
                  <div class="b3-col-md-9 b3-col-sm-offset-3">
                    <label for="participant_registration_accommodations_accommodation">
                      Please provide details about your accommodation request.
                    </label>

                    <input type="text" id="participant_registration_accommodations_accommodation"
                           aria-describedby="participant_registration_accommodations_accommodation_help"
                           v-model="participant.accommodations.accommodation"
                           maxlength="255"
                           class="form-control">
                    <p class="help-block" id="participant_registration_accommodations_accommodation_help">
                      Please note that although we will do what we can to meet your needs, not all accommodation
                      requests are guaranteed.
                    </p>

                    <div v-show="errors.length" class="help-block">*This field is required.</div>
                  </div>
                </div>
              </validation-provider>
              <validation-provider rules="required" v-slot="{ errors, classes }">
                <div class="form-group required" :class="classes">
                  <label class="control-label b3-col-sm-offset-3 b3-col-sm-9" style="text-align: left;">
                    I am registering for this workshop for myself.
                  </label>
                  <div class="block b3-col-sm-offset-3 b3-col-sm-9">
                    <div id="participant_registration_self_register">
                      <div class="radio">
                        <label for="participant_registration_self_register_0">
                          <input type="radio"
                                 id="participant_registration_self_register_0"
                                 v-model="participant.self_register"
                                 required="required"
                                 value="1"/> Yes
                        </label></div>
                      <div class="radio">
                        <label for="participant_registration_self_register_1">
                          <input type="radio"
                                 id="participant_registration_self_register_1"
                                 v-model="participant.self_register"
                                 required="required"
                                 value="0"/> No
                        </label>
                      </div>
                    </div>

                    <div v-show="errors.length" class="help-block">*This field is required.</div>
                  </div>
                </div>
              </validation-provider>
              <hr>

            </div>
          </div>
          <div class="panel-body" v-if="!isLoading && notFound">We're sorry, but this workshop is not accepting new
            registrations. The workshop may be full or the registration deadline has passed.
          </div>
          <div class="panel-footer" v-if="!isLoading && !notFound">
            <validation-provider rules="required" v-slot="{ errors, classes }">
              <div class="form-group required" :class="classes">
                <div class="form-check nowrap b3-col-sm-12" style="margin-bottom: 5px;">
                  <div class="checkbox">
                    <input class="form-check-input" style="margin-left: 0;" v-model="participant.consentShareInformation" type="checkbox" value="true" id="consentShareInfo">
                    <label class="form-check-label" style="font-weight: 400;" for="consentShareInfo">
                      I agree to the <a href="#" v-on:click.prevent="showConsent = true">Data Sharing Agreement</a> which allows us to share your contact information with the workshop provider you chose.
                      <div v-show="errors.length" class="help-block">*This field is required.</div>
                    </label>
                  </div>
                </div>
              </div>
            </validation-provider>
            <button :disabled="isSubmitting || isLoading" type="submit" class="btn btn-success btn-lg">
              Register
            </button>&nbsp;
            <div v-show="hasError" class="alert alert-danger pull-right">
              <div v-if="!errorMessages">There were errors in the data you have submitted.</div>
              <div v-if="errorMessages" v-html="errorMessages.join('<br>')"></div>
            </div>
          </div>
        </div>
        <div class="well" v-if="hasRegistrationMessage && registrationMessagePosition == 'bottom'">
          <small v-html="registrationMessage"></small>
        </div>
      </div>
    </div>
    <workshop-information-modal v-model="infoWorkshop" :show-enroll="false"/>
    <consent-modal v-model="showConsent"/>
  </validation-observer>
</template>

<script>
import {getReferralOptions, getWorkshop, registerForWorkshop, getLanguages} from '../api/api'

import NProgress from 'nprogress'
import WorkshopInformationModal from "./WorkshopInformationModal";
import WorkshopLocation from "./WorkshopLocation"
import RegistrationNameAndContact from "./fields/RegistrationNameAndContact.vue";
import RegistrationLanguageReferral from "./fields/RegistrationLanguageReferral.vue";
import WorkshopStartDate from "./util/WorkshopStartDate.vue";
import ConsentModal from "./ConsentModal.vue";

export default {
  components: {
    ConsentModal,
    WorkshopStartDate,
    WorkshopLocation, WorkshopInformationModal, RegistrationNameAndContact, RegistrationLanguageReferral},
  props: {
    showReturn: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      showConsent: false,
      showTerms: false,
      isLoading: true,
      hasError: false,
      notFound: false,
      isSubmitting: false,
      errorMessages: [],
      workshop: {},
      participant: {address: {}, accommodations: {}, emailProfile: {}, smsProfile: {}},
      infoWorkshop: null,
    }
  },

  created() {
    if (typeof this.$compassOptions.participant !== 'undefined') {
      this.participant = this.$compassOptions.participant;
    }

    this.fetchData()
  },

  beforeRouteUpdate(to, from, next) {
    this.notFound = false
    this.fetchData().then(() => next())
  },

  computed: {
    registrationMessagePosition() {
      if (typeof this.$compassOptions.registration_message_position !== 'undefined') {
        const position = this.$compassOptions.registration_message_position;

        if (['top', 'bottom'].indexOf(position) === -1) {
          return 'top';
        }

        return position;
      }

      return 'top';
    },

    hasRegistrationMessage() {
      return typeof this.$compassOptions.registration_message !== 'undefined';
    },

    registrationMessage() {
      if (typeof this.$compassOptions.registration_message !== 'undefined') {
        return this.$compassOptions.registration_message;
      }
      return "";
    },

    askAccommodation() {
      return this.$compassOptions.request_accommodation;
    },

    askAddress() {
      if (typeof this.$compassOptions.ask_address !== 'undefined') {
        return this.$compassOptions.ask_address;
      }

      return true;
    },

    askDob() {
      if (typeof this.$compassOptions.ask_dob !== 'undefined') {
        return this.$compassOptions.ask_dob;
      }

      return false;
    },

    requireAddress() {
      if (this.workshop.program.type == 'SELF_DIRECTED') {
        return true;
      }

      if (typeof this.$compassOptions.require_address !== 'undefined') {
        return this.$compassOptions.require_address;
      }

      return false;
    },

    requireEmail() {
      return this.$requireEmail;
    },

  },
  methods: {
    openWorkshopDetails() {
      this.infoWorkshop = this.workshop
    },

    fetchData() {

      this.isLoading = true
      this.hasResults = true
      this.invalidQuery = false

      const params = this.$route.params

      return Promise.all([getWorkshop(params.id), getReferralOptions(), getLanguages()]).then((values) => {
        this.workshop = values[0].data
        this.referralOptions = values[1].data
        this.languages = values[2].data
        this.participant.language = this.workshop.language.id
        this.isLoading = false
        NProgress.done()
      }).catch(() => {
        this.isLoading = false
        this.notFound = true
        NProgress.done()
      })
    },

    async submitRegistration() {
      const isValid = await this.$refs.form.validate()

      if (!isValid) {
        this.hasError = true
        return;
      }

      NProgress.start()

      this.isSubmitting = true
      registerForWorkshop(
          this.workshop.id,
          this.participant,
          this.requireAddress,
          this.requireEmail,
          this.$compassOptions.callback_url
      ).then(() => {
        NProgress.done()

        this.isSubmitting = false
        this.participant = {}

        // not sure 100% why this push causes an uncaught error in console,
        // but explanation is here: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
        // and this thread: https://github.com/vuejs/vue-router/issues/2911
        this.$router.push({
          name: 'registration_success',
          params: {
            id: this.workshop.id
          }
        }).catch()
      }).catch((errResp) => {
        this.isSubmitting = false
        NProgress.done()

        this.hasError = true

        const response = errResp.response

        if (response.status == 400) {
          let errors = {}
          this.buildErrors(errors, response.data.errors);

          this.$refs.form.setErrors(errors);

          if (errors.length) {
            this.hasError = true
          }
        }
      })
    },

    buildErrors(errorArray, responseErrors) {
      for (var key in responseErrors['children']) {
        if (responseErrors['children'][key].errors) {
          errorArray[key] = responseErrors['children'][key].errors
        } else if (responseErrors['children'][key]['children']) {
          this.buildErrors(errorArray, responseErrors['children'][key])
        }
      }
    },
  },


}

</script>