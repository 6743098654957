<template>
  <div id="ws-listing">
    <SearchForm />
    <SearchResults />
  </div>
</template>

<script>
  import SearchForm from './SearchForm.vue'
  import SearchResults from './SearchResults.vue'

  export default {
    name: 'find-a-workshop',
    components: {
      SearchForm,
      SearchResults
    }
  }
</script>
