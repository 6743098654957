<template>
    <router-link :to="{ name: 'registration', params: { id: workshop.id }}" v-if="!workshop.public_listing_info_only && workshop.isFull" :class="buttonClass" href="">Join Waitlist</router-link>
    <router-link :to="{ name: 'registration', params: { id: workshop.id }}" v-else-if="!workshop.public_listing_info_only && !workshop.isFull" :class="buttonClass"><strong>Register</strong></router-link>
</template>

<script>
    export default {
        props: {
            size: {
              type: String,
              default: 'sm'
            },

            workshop: {
                type: Object,
                required: true
            }
        },
        computed: {
          buttonClass: function() {
            return 'btn btn-success btn-' + this.size
          }
        }
    }
</script>