<template>
  <div class="b3-row margin-top-20">
    <div class="b3-col-md-5">
      <div class="well">
        <GmapMap ref="mapRef" v-show="workshops.length > 0"
                 :options="{
                            zoomControl: true,
                         }"
                 :zoom="5"
                 :center="mapCenter"
                 map-type-id="roadmap"
                 style="width: 100%; height: 490px;"
        >
          <GmapMarker
              :key="index"
              :icon="mapIcon(index)"
              v-for="(workshop, index) in workshops"
              :position="workshopLatLng(workshop)"
              :clickable="true"
              :draggable="true"
              @click="updateWorkshop(workshop)"
          />
        </GmapMap>
      </div>
    </div>
    <div class="b3-col-md-7">
      <div class="table-responsive">
        <table class="table table-highlight mobile">
          <thead>

          <tr>
            <th scope="col">Workshop</th>
            <th class="text-center">Date</th>
            <th scope="col"><span class="sr-only">Registration Options</span></th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="(workshop, idx) in workshops" v-bind:key="workshop.id" class="vertical-center">
            <td>
              <img :src="mapIcon(idx)" style="display: inline-block; padding-right: 5px;" :alt="'Map Letter ' + letter(idx)" aria-hidden="true"/>
              <a href="#" v-on:click.prevent="updateWorkshop(workshop)">{{ workshop.local_program_name }}</a>
            </td>
            <td class="text-center" v-if="!workshop.start_datetime">Ongoing</td>
            <td v-if="workshop.start_datetime">{{ workshop.start_datetime | moment("dddd - MMM D, YYYY") }}</td>
            <td class="text-center">
              <enroll-button :workshop="workshop"/>
              <a v-if="workshop.public_listing_info_only" href="#" v-on:click.prevent="updateWorkshop(workshop)"
                 class="btn btn-sm btn-info">Information</a>
            </td>
          </tr>
          </tbody>
        </table>
        <workshop-information-modal v-model="currentWorkshop"/>
      </div>
    </div>
  </div>

</template>

<script>
import WorkshopInformationModal from './WorkshopInformationModal'
import EnrollButton from "./util/EnrollButton";

export default {
  name: 'search-results-table',

  components: {
    EnrollButton,
    WorkshopInformationModal
  },

  data() {
    return {
      currentWorkshop: null
    }
  },

  mounted() {
    // when map is loaded, set zoom and map bounds to fit all pointers.
    this.$refs.mapRef.$mapPromise.then((map) => {
      let bound_ws_map = new google.maps.LatLngBounds();

      this.workshops.forEach((workshop) => {
        if (workshop.implementation_site.address.latitude && workshop.implementation_site.address.longitude) {
          bound_ws_map.extend(new google.maps.LatLng(workshop.implementation_site.address.latitude, workshop.implementation_site.address.longitude));
        }
      })
      map.fitBounds(bound_ws_map);
    })
  },

  computed: {
    mapCenter() {
      let lats = [];
      let lngs = [];

      this.workshops.forEach((workshop) => {
        let latLng = this.workshopLatLng(workshop);

        if (latLng !== null) {
          lats.push(latLng.lat);
          lngs.push(latLng.lng);
        }
      })

      const add = (a, b) => a + b

      return {
        lat: lats.reduce(add) / lats.length,
        lng: lngs.reduce(add) / lngs.length
      }
    }
  },
  props: {
    workshops: Array
  },

  methods: {
    workshopLatLng (workshop) {
      if (workshop.implementation_site.address.latitude && workshop.implementation_site.address.longitude) {
        return {
          lat: parseFloat(workshop.implementation_site.address.latitude),
          lng: parseFloat(workshop.implementation_site.address.longitude),
        }
      }
      return null;
    },

    mapIcon(number) {
      return 'http://maps.google.com/mapfiles/marker' + this.letter(number) + '.png'
    },

    letter(number) {
      return String.fromCharCode(65 + number)
    },

    updateWorkshop(workshop) {
      this.currentWorkshop = workshop
    }
  }
}
</script>