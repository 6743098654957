import Vue from 'vue'

import { ValidationProvider, ValidationObserver, extend, setInteractionMode, configure } from 'vee-validate';
import { required, email, numeric, regex, alpha, length, required_if, max, min } from 'vee-validate/dist/rules';

configure({
    bails: false,
    classes: {
        valid: 'is-valid', // one class
        invalid: ['is-invalid', 'has-error'] // multiple classes
    }
});
setInteractionMode('eager');

extend('required', required)
extend('email', email)
extend('regex', regex)
extend('max', max)
extend('alpha', alpha)
extend('length', length)
extend('required_if', required_if)
extend('numeric', numeric)
extend('min', min)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

export default {}