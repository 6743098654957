<template>
<div>
  <validation-provider rules="required" v-slot="{ errors, classes }">
    <div class="form-group required" :class="classes">
      <label class="b3-col-sm-3 control-label required" for="workshop_reg_form_first_name">
        First Name
      </label>
      <div class="b3-col-sm-6">
        <input type="text" id="workshop_reg_form_first_name"
               v-model="participant.first_name"
               class="form-control">
      </div>
      <div v-show="errors.length" class="help-block b3-col-sm-9 b3-col-sm-offset-3">First Name is required.</div>
    </div>
  </validation-provider>
  <validation-provider rules="required" v-slot="{ errors, classes }">
    <div class="form-group" :class="classes">
      <label class="b3-col-sm-3 control-label required" for="workshop_reg_form_last_name">
        Last Name
      </label>
      <div class="b3-col-sm-6">
        <input type="text" id="workshop_reg_form_last_name"
               v-model="participant.last_name"
               class="form-control">
      </div>
      <div v-show="errors.length" class="help-block b3-col-sm-9 b3-col-sm-offset-3">Last Name is required.</div>
    </div>
  </validation-provider>
  <validation-provider :rules="{ required : requireEmail }" v-slot="{ errors, classes }">
    <div class="form-group" :class="classes">
      <label class="b3-col-sm-3 b3-col-sm-3 control-label" :class="{ 'required' : requireEmail }" for="workshop_reg_form_email">
        E-mail Address
      </label>
      <div class="b3-col-sm-6">
        <input type="email"
               id="workshop_reg_form_email"
               v-model="participant.email"
               class="form-control">
      </div>
      <div v-show="errors.length" class="help-block b3-col-sm-9 b3-col-sm-offset-3">A valid e-mail address is
        required.
      </div>
    </div>
  </validation-provider>


  <validation-provider v-if="participant.email">
    <div class="form-group">

      <div class="block b3-col-sm-offset-3 b3-col-sm-9">
        <div class="checkbox">
          <label for="participant_registration_send_email">
            <input type="checkbox"
                   id="participant_registration_send_email"
                   v-model="participant.emailProfile.optIn"
                   value="0"/> I would like to receive emails, such as a registration confirmation and class reminders, related to my workshop(s).
          </label>
        </div>
      </div>
    </div>
  </validation-provider>
  <validation-provider
      vid="phone_number"
      rules="required"
      v-slot="{ errors, classes }"
      :custom-messages="{required: 'Phone Number is required.'}"
  >
    <div class="form-group" :class="classes">
      <label class="b3-col-xs-12 b3-col-sm-3 control-label required" for="workshop_reg_form_phone_number">
        Phone Number
      </label>
      <div class="b3-col-xs-8 b3-col-sm-4">
        <input type="text" id="workshop_reg_form_phone_number"
               v-model="participant.phone_number"
               class="form-control input-medium form-control"
               name="phone_number"
               v-mask="'(###) ###-####'"
               autocomplete="off" maxlength="14">
      </div>
      <div v-show="errors.length" class="help-block b3-col-xs-12 b3-col-sm-9 b3-col-sm-offset-3">
        <span v-html="errors.join('<br>')"></span>
      </div>
    </div>
  </validation-provider>
  <validation-provider vid="optIn" v-slot="{ errors, classes }" v-if="smsEnabled">
    <div class="form-group" :class="classes">
      <div class="b3-col-sm-offset-3 b3-col-sm-8">
        <div class="checkbox">
          <label for="participant_registration_sms_opt_in">
            <input type="checkbox"
                   id="participant_registration_sms_opt_in"
                   v-model="participant.smsProfile.optIn"
                   value="0"/> Please send me SMS Text Messages, such as class reminders, related to my workshop.
            <a @click="showTerms = true" v-if="showTerms == false">Full Terms and Conditions</a>
            <div v-if="showTerms" style="margin-top: 1rem;">
              By checking this box and clicking continue, I acknowledge that I consent to receiving text messages from Compass and the workshop provider related to my workshops.
              I understand that my consent to such messages is not required to use Compass's services or attend workshops and that I may revoke my consent at any time by replying STOP.
              Message and data rates may apply.  Message frequency may vary.
            </div>
          </label>
        </div>
      </div>
      <div v-show="errors.length" class="help-block b3-col-xs-12 b3-col-sm-9 b3-col-sm-offset-3">
        {{ errors }}
      </div>
    </div>
  </validation-provider>
  <hr>
  <div v-if="askAddress">
    <validation-provider :rules="{ required: requireAddress }" v-slot="{ errors, classes }">
      <div class="form-group" :class="classes">
        <label v-bind:class="['b3-col-sm-3 b3-col-sm-3 control-label', requireAddress ?  'required' : '']"
               for="workshop_reg_form_address_address">
          Street Address
        </label>
        <div class="b3-col-sm-8">
          <input type="text"
                 id="workshop_reg_form_address_address"
                 v-model="participant.address.address"
                 class="form-control">
        </div>
        <div class="b3-col-sm-9 b3-col-sm-offset-3">
          <p class="help-block">Cannot send mail to P.O. Box.</p>
        </div>
        <div v-show="errors.length" class="help-block b3-col-sm-9 b3-col-sm-offset-3">Street Address is required.
        </div>
      </div>
    </validation-provider>
    <div class="form-group">
      <label class="b3-col-sm-3 b3-col-sm-3 control-label" for="workshop_reg_form_address_address_cont">
        Address Line 2
      </label>
      <div class="b3-col-sm-8">
        <input type="text"
               id="workshop_reg_form_address_address_cont"
               v-model="participant.address.address_cont"
               class="form-control"/>
      </div>
    </div>
    <validation-provider :rules="{ required: requireAddress }" v-slot="{ errors, classes }">
      <div class="form-group" :class="classes">
        <label v-bind:class="['b3-col-sm-3 b3-col-sm-3 control-label', requireAddress ?  'required' : '']"
               for="workshop_reg_form_address_city">
          City
        </label>
        <div class="b3-col-sm-6">
          <input type="text"
                 id="workshop_reg_form_address_city"
                 v-model="participant.address.city"
                 class="form-control">
        </div>
        <div v-show="errors.length" class="help-block b3-col-sm-9 b3-col-sm-offset-3">City is required.</div>
      </div>
    </validation-provider>
    <validation-provider :rules="{ required: requireAddress }" v-slot="{ errors, classes }">
      <div class="form-group" :class="classes">
        <label v-bind:class="['b3-col-sm-3 b3-col-sm-3 control-label', requireAddress ?  'required' : '']"
               for="workshop_reg_form_address_state">
          State
        </label>
        <div class="b3-col-sm-5">
          <state-dropdown v-model="participant.address.state" id="workshop_reg_form_address_state"/>
        </div>
        <div v-show="errors.length" class="help-block b3-col-sm-9 b3-col-sm-offset-3">State is required.</div>
      </div>
    </validation-provider>
    <validation-provider vid="zip" :rules="{ required: requireAddress }" v-slot="{ errors, classes }">
      <div class="form-group" :class="classes">
        <label v-bind:class="['b3-col-xs-12 b3-col-sm-3 control-label', requireAddress ?  'required' : '']"
               for="workshop_reg_form_address_zip">
          Zip Code
        </label>
        <div class="b3-col-xs-6 b3-col-sm-4">
          <input type="text"
                 id="workshop_reg_form_address_zip"
                 v-model="participant.address.zip"
                 v-mask="'#####'"
                 class="form-control"/>
        </div>
        <div v-show="errors.length" class="help-block b3-col-xs-12  b3-col-sm-9 b3-col-sm-offset-3">
          {{ errors.join('<br>')}}
        </div>
      </div>
    </validation-provider>
  </div>
</div>
</template>

<script>
import StateDropdown from "./StateDropdown.vue";

export default {
  components: {StateDropdown},
  props: {
    requireAddress: {
      type: Boolean,
      required: true,
    },
    participant: {
      type: Object,
      required: true
    },
    smsEnabled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      showTerms: false,
    }
  },
  computed: {

    requireEmail() {
      return this.$requireEmail;
    },

    askAddress() {
      if (typeof this.$compassOptions.ask_address !== 'undefined') {
        return this.$compassOptions.ask_address;
      }

      return true;
    },
  }
}
</script>