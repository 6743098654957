import Vue from 'vue'

import VueRouter from 'vue-router'
import NProgress from "nprogress";

import FindAWorkshop from './components/FindAWorkshop'
import WorkshopInterest from './components/WorkshopInterest'
import Registration from './components/Registration'
import RegistrationSuccess from "./components/RegistrationSuccess";

Vue.use(VueRouter)

const routes = [
    { path: '/', component: FindAWorkshop, name: 'find_a_workshop' },
    { path: '/workshop-interest', component: WorkshopInterest, name: 'workshop_interest' },
    { path: '/registration/:id', component: Registration, name: 'registration' },
    { path: '/registration-success/:id', component: RegistrationSuccess, name: 'registration_success' }
]

function hasQueryParams(route) {
    return !!Object.keys(route.query).length
}

export default function buildRouter (historyMode, basePath) {
    const router = new VueRouter({
        base: basePath,
        routes,
        mode: historyMode,
        scrollBehavior () {
            return { x: 0, y: 0 }
        }
    })

    router.beforeEach((to, from, next) => {
        if(!hasQueryParams(to) && hasQueryParams(from)){
            next({...to, ...{query: from.query}});
        } else {
            next()
        }
    })

    router.beforeResolve((to, from, next) => {
        if (to.name) {
            NProgress.start()
        }

        next()
    })

    router.afterEach(() => {
        NProgress.done()
    })

    return router
}