<style type="text/css" scoped>
    .alert-success {
        padding: 5px 10px !important;
    }
</style>
<template>
    <validation-observer ref="observer" tag="form" class="form-horizontal" @submit.prevent="submitInterestRequest" v-slot="{ interestInvalid }" v-if="!isLoading">
        <div class="panel panel-default">
            <div class="panel-heading">
                <strong>Workshop Interest Request</strong>

                <router-link v-if="showReturn" :to="{ name: 'find_a_workshop' }" class="btn btn-danger pull-right btn-xs">Return to Find-a-Workshop&nbsp;&nbsp;<i class="fa fa-arb3-row-circle-o-right"></i></router-link>
            </div>
            <div class="panel-body">
                <p>If you are unable to locate a workshop that meets your needs, please fill out the form below and we will make every effort to connect
                    you with an organization that can help you further.
                </p>
                <hr />
                <div id="workshop_user_interest_form">
                    <div class="form-group">
                        <label class="b3-col-sm-3 control-label" for="workshop_user_interest_form_program_interest">
                            Program of Interest
                        </label>
                        <div class="b3-col-sm-8 clearfix">
                            <program-dropdown v-model="workshopInterest.program_interest" id="workshop_user_interest_form_program_interest"/>
                        </div>
                    </div>
                    <registration-name-and-contact :sms-enabled="false" :require-address="requireAddress" :participant="workshopInterest"></registration-name-and-contact>
                    <registration-language-referral :participant="workshopInterest"></registration-language-referral>
                    <validation-provider rules="required" v-slot="{ errors, classes }">
                        <div class="form-group" :class="classes">
                            <label class="b3-col-sm-3 b3-col-sm-3 control-label required" for="workshop_user_interest_form_preferred_contact_method">
                                Preferred Contact Method
                            </label>
                            <div class="b3-col-sm-6 clearfix">
                                <select id="workshop_user_interest_form_preferred_contact_method"
                                        v-model="workshopInterest.preferred_contact_method"
                                        form_group_class="b3-col-sm-6"
                                        class="form-control">
                                    <option value="phone">Phone</option>
                                    <option value="email">E-mail</option>
                                </select>
                            </div>
                            <div v-show="errors.length" class="help-block b3-col-sm-9 b3-col-sm-offset-3">Preferred Contact Method is required.</div>
                        </div>
                    </validation-provider>

                    <div class="form-group">
                        <label class="b3-col-sm-3 b3-col-sm-3 control-label" for="workshop_user_interest_form_comments">
                            Additional Comments
                        </label>
                        <div class="b3-col-sm-9 clearfix">
                        <textarea id="workshop_user_interest_form_comments"
                                  v-model="workshopInterest.comments"
                                  class="form-control">
                        </textarea>
                    </div>
                </div>
            </div>
            </div>
            <div class="panel-footer">
                <button :disabled="isSubmitting" type="submit" class="btn btn-primary">Submit Request&nbsp;&nbsp;<small class="glyphicon glyphicon-arb3-row-right"></small></button>&nbsp;
                <router-link v-if="showReturn" :to="{ name: 'find_a_workshop' }" class="btn btn-danger">Cancel</router-link>
                <div v-show="hasSuccess" class="alert alert-success pull-right">
                    Your request has been submitted successfully!
                </div>
            </div>
        </div>
    </validation-observer>
</template>

<script>
import ProgramDropdown from './fields/ProgramDropdown'
import {getLanguages, getReferralOptions, submitInterestRequest} from '../api/api'
import RegistrationNameAndContact from "./fields/RegistrationNameAndContact.vue";
import RegistrationLanguageReferral from "./fields/RegistrationLanguageReferral.vue";

export default {
        components: {ProgramDropdown, RegistrationNameAndContact, RegistrationLanguageReferral},
        props: {
            showReturn: {
                type: Boolean,
                required: false,
                default: true
            }
        },

        data() {
            return {
                isLoading: true,
                isSubmitting: false,
                hasSuccess: false,
                workshopInterest: {
                    program_interest: null,
                    address: {},
                    language: {},
                    smsProfile: {},
                    emailProfile: {}
                },
              languages: [],
              referralOptions: [],
            }
        },

        created: function () {
          this.fetchData();
        },

        beforeRouteUpdate: function() {
          this.fetchData
        },

        computed: {
          requireAddress() {
            if (typeof this.$compassOptions.require_address !== 'undefined') {
              return this.$compassOptions.require_address;
            }

            return false;
          },

          relationshipRequiresInput() {
            if (!this.workshopInterest.referralMethod) {
              return;
            }

            return this.referralOptions.filter(option => option.id == this.workshopInterest.referralMethod).shift().require_input;
          }
        },

        methods: {
            fetchData() {
              this.isLoading = true;
              return Promise.all([getLanguages(), getReferralOptions()]).then((values) => {
                this.languages = values[0].data;
                this.referralOptions = values[1].data;
                this.isLoading = false;
              })
            },

            async submitInterestRequest () {
                const isValid = await this.$refs.observer.validate();
                if (!isValid) {
                    return;
                }

                this.isSubmitting = true

                submitInterestRequest(this.workshopInterest).then((resp) => {

                    this.isSubmitting = false

                    if (resp.status == 200) {
                      this.workshopInterest = {
                        program_interest: null,
                        address: {}
                      }

                      this.$refs.observer.reset();

                      this.hasSuccess = true
                    }
                }).catch(() => {

                    this.isSubmitting = false
                })
            }
        }
    }

</script>