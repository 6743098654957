<template>
  <span v-if="workshop.facilitated_enrollment_date">Ongoing</span>
  <span v-else-if="workshop.program.schedule_generator_type == 'ONGOING'">Ongoing Enrollment</span>
  <span v-else-if="!workshop.start_datetime && !workshop.facilitated_enrollment_date">Ongoing</span>
  <span v-else-if="workshopStartDate()">
        <strong>{{ workshopStartDate() | moment("MMMM D") }}</strong> @
        {{ workshopStartDate() | moment("h:mmA") }}
        <em>({{ workshopStartDate() | moment("dddd") }})</em>
    </span>
</template>


<script>
export default {
  name: 'workshop-start-date',
  props: {
    workshop: {
      type: Object,
      required: true
    }
  },
  methods: {
    workshopStartDate() {
      if (this.workshop.start_datetime) {
        return this.workshop.start_datetime
      }

      if (this.workshop.facilitated_enrollment_date) {
        return this.workshop.facilitated_enrollment_date
      }

      return null
    }
  }
}
</script>