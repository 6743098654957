<style type="text/css">

</style>
<template>
<div>
  <div class="row-ws row-ws-header border-bottom">

    <div class="col-ws col-ws-info">
      <em class="text-muted">Please click a workshop's name to learn more.</em>
    </div>
    <div class="col-ws col-ws-delivery">

    </div>
    <div class="col-ws col-ws-date">
      <strong>Start Date</strong>
    </div>
    <div class="col-ws col-ws-language">
      <strong>Language</strong>
    </div>

    <div class="col-ws col-ws-links">
        <span class="sr-only">Registration Options</span>
    </div>
  </div>
  <div v-for="(workshop) in workshops" v-bind:key="workshop.id" class="row-ws border-bottom">
    <div class="col-ws col-ws-info">
      <a href="#" v-on:click.prevent="updateWorkshop(workshop)" class="ws-name">{{ workshop.local_program_name }}</a>
      <div class="location-info">
        <div v-if="workshop.program.type != 'SELF_DIRECTED'">
          {{ workshop.implementation_site.name }}
          <div v-if="workshop.implementation_site.delivery_method.value == 'in_person'">
            {{ workshop.implementation_site.address.city }}, {{ workshop.implementation_site.address.state }}
          </div>
          <div v-else class="ws-provided-by">
            Provided by: {{ workshop.implementation_site.host_site.name }}
          </div>
        </div>
        <div v-else class="ws-provided-by">
          Provided by: {{ workshop.implementation_site.host_site.name }}
        </div>
        <div class="label label-success" v-if="workshop.implementation_site.accessible">Accessible</div>
      </div>
    </div>
    <div class="col-ws col-ws-delivery">
      <delivery-method-label :workshop="workshop" />
    </div>
    <div class="col-ws col-ws-date">
      <strong class="sm-label">Start Date:</strong>
      <workshop-start-date :workshop="workshop"></workshop-start-date>
    </div>
    <div class="col-ws col-ws-language">
      <strong class="sm-label">Language:</strong>
      {{ workshop.languageName }}
    </div>
    <div class="col-ws col-ws-links">
      <a v-on:click.prevent="updateWorkshop(workshop)" class="btn btn-sm btn-info" v-if="workshop.public_listing_info_only">Details</a>
      <enroll-button :workshop="workshop" />
      </div>
  </div>

  <workshop-information-modal v-model="currentWorkshop" />
</div>
</template>

<script>

import WorkshopInformationModal from './WorkshopInformationModal'
import EnrollButton from "./util/EnrollButton";
import WorkshopStartDate from "./util/WorkshopStartDate.vue";
import DeliveryMethodLabel from "./util/DeliveryMethodLabel.vue";

export default {
        name: 'search-results-table',

        components: {
          DeliveryMethodLabel,
          WorkshopStartDate,
            EnrollButton,
            WorkshopInformationModal
        },

        data () {
            return {
                currentWorkshop: null
            }
        },

        props: {
            workshops: Array
        },
        methods: {
            letter (number) {
                return String.fromCharCode(65 + number)
            },

            updateWorkshop (workshop) {
                this.currentWorkshop = workshop
            },
        }
    }
</script>