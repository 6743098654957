
<template>
    <div ref="searchContainer" class="b3-row" v-show="!isLoading">
        <div class="b3-col-md-12">
            <tabs v-if="hasResults && !invalidQuery">
                <tab title="Search Results">
                    <SearchResultsTable :workshops="workshops" />
                </tab>
                <tab title="View Map">
                    <SearchResultsMap :workshops="workshops" v-if="workshops.length > 0"/>
                </tab>
            </tabs>
            <div class="alert alert-danger" v-if="!isLoading && invalidQuery">Please enter a ZIP code or select a State/Region.</div>
            <div v-if="!hasResults">
                <div class="alert alert-danger" v-if="!isLoading && !hasResults">No workshops could be located based on your search criteria.  <span v-if="ownerArgumentSet">Please feel free to fill out the interest request form below.</span></div>
                <workshop-interest :show-return="false" v-if="ownerArgumentSet"></workshop-interest>
            </div>

        </div>
    </div>
</template>

<script>
import { Tabs, Tab } from 'uiv'
import SearchResultsTable from './SearchResultsTable'
import SearchResultsMap from './SearchResultsMap'
import { getWorkshops } from "../api/api"
import WorkshopInterest from "./WorkshopInterest"
import NProgress from 'nprogress'

export default {
    components: {
        WorkshopInterest,
        SearchResultsTable,
        SearchResultsMap,
        Tabs,
        Tab
    },

    data () {
        return {
            isLoading : true,
            hasResults : false,
            workshops: [],
            invalidQuery : false,
        }
    },

    watch: {
        // call again the method if the route changes
      $route() {
        this.fetchData()
      }
    },

    created () {
        this.fetchData()
    },

    methods: {
        fetchData () {
            this.isLoading = true
            this.hasResults = true
            this.invalidQuery = false

            const query = this.$route.query

            return new Promise((resolve, reject) => {

                if (!this.ownerArgumentSet && !query.zipCode && !query.stateId) {
                    this.invalidQuery = true
                    this.isLoading = false
                    resolve()
                    return
                }

                NProgress.start()
                getWorkshops(query.zipCode, query.distance || 30, query.programId, query.stateId, query.deliveryMethod).then((resp) => {
                  this.workshops = resp.data

                  if (!this.workshops.length) {
                      this.hasResults = false
                  } else {
                      this.hasResults = true
                  }

                  this.isLoading = false


                  this.$nextTick(() => {
                    //perform auto scroll if we are searching for something.
                    if (Object.keys(query).length > 0) {
                      this.$refs.searchContainer.scrollIntoView({behavior: 'smooth'})
                    }
                  })

                  NProgress.done()
                  resolve()
              }).catch(() => {
                  reject()
                  NProgress.done()
              })
            })
        }
    }
}

</script>