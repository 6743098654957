const axios = require('axios');

let programs = [];
export function getPrograms() {
    if (programs.length > 0) {
        return programs;
    }

    return axios.get("/api/json/v1/programs/");
}

export function getOwners() {
    return axios.get("/api/json/v1/owners/list");
}


export function getStates() {
    return axios.get("/api/json/v1/owners/states");
}


export function getLanguages() {
    return axios.get("/api/json/v1/languages");
}


export function getWorkshops(zipCode, distance, programId, stateId, deliveryMethod) {
    var groupId = null;

    if (programId && programId.toString().startsWith('group_')) {
        groupId = programId.toString().replace('group_', '');
    }

    const query = encodeQueryData({
        zipCode : zipCode,
        distance : distance,
        programId : programId,
        groupId: groupId,
        stateId : stateId,
        deliveryMethod: deliveryMethod
    })
    return axios.get("/api/json/v1/ws/list?" + query);
}

export function getWorkshop(workshopId) {
    return axios.get("/api/json/v1/ws/view/" + workshopId);
}

export function submitInterestRequest(interestRequest) {
    return axios.post("/api/json/v1/ws/interest-request/v2", {
        search_interest_request : interestRequest
    })
}

export function registerForWorkshop(workshopId, participant, requireAddress, requireEmail, callbackUrl) {
    return axios.post("/api/json/v1/participant/create", {
        workshop_id: workshopId,
        registration: participant,
        callback_url: callbackUrl,
        require_address: requireAddress,
        require_email: requireEmail
    })
}

export function getReferralOptions() {
    return axios.get("/api/json/v1/participant/relationship-options")
}

function encodeQueryData(data) {
    const ret = []

    for (let d in data) {
        if (!data[d]) {
            continue
        }
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    }

    return ret.join('&');
}