<template>
  <div>
    <div class="b3-row">
      <div v-bind:class="{ 'b3-col-md-6' : workshop.program.type != 'SELF_DIRECTED', 'b3-col-md-12' : workshop.program.type == 'SELF_DIRECTED' }">
        <div v-if="workshop.program.type != 'SELF_DIRECTED'">
        <h3 class="short_headline">LOCATION</h3>
        <workshop-location :workshop="workshop"/>
        </div>
        <h3 class="short_headline">Program Description</h3>
        <div v-html="workshop.program.description"></div>
        <div v-if="workshop.description">
          <hr/>
          <h3 class="short_headline">Workshop Description</h3>
          <div v-html="workshop.description"></div>
        </div>
        <div v-if="showEnroll">
          <hr/>
          <h3 class="short_headline">TO ENROLL</h3>

          <div class="b3-row">
            <div class="b3-col-md-12">
              <p v-if="!workshop.public_listing_info_only">
                Press the "Register" button below for online registration or contact our office <span v-if="workshop.contact_phone"> at <strong>{{ workshop.contact_phone | phone }} </strong> or</span> via e-mail at <a :href="'mailto:' +  workshop.contact_email">{{ workshop.contact_email }}</a>.
              </p>
              <p v-if="workshop.public_listing_info_only">
                If you would like to register for this workshop, please contact our office <span v-if="workshop.contact_phone"> at <strong>{{ workshop.contact_phone | phone }} </strong> or</span> via e-mail at <a :href="'mailto:' +  workshop.contact_email">{{ workshop.contact_email }}</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-bind:class="{ 'b3-col-md-6' : workshop.program.type != 'SELF_DIRECTED', 'b3-col-md-12' : workshop.program.type == 'SELF_DIRECTED' }">
        <div class="panel panel-default p-0" v-if="workshop.schedule.length">
          <div class="panel-heading" v-if="workshop.session_zero && workshop.session_zero_datetime">
            <strong>INFORMATIONAL SESSION</strong>
          </div>
          <div class="panel-body border-bottom" v-if="workshop.session_zero && workshop.session_zero_datetime">
            <p>This workshop will have an initial informational session on
              <strong>{{ workshop.session_zero_datetime | moment("dddd, MMMM Do, YYYY") }}</strong> at
              <strong>{{ workshop.session_zero_datetime | moment("h:mmA") }}</strong>.</p>
            <p v-if="workshop.session_zero_description" v-html="workshop.session_zero_description"/>
          </div>
          <div class="panel-heading"><strong>SCHEDULE</strong></div>
          <div class="panel-body" v-if="workshop.schedule_generator && workshop.schedule_generator.days.length > 0">

            <p v-if="workshop.program.group_session_types.length == 1">
              This is an ongoing workshop that hosts {{ workshop.program.group_session_types[0].name }}s on the
              following days:
            </p>
            <p v-else>
              This is an ongoing workshop that hosts sessions on the following days:
            </p>
            <ul>
              <li v-for="(day, dayIdx) in workshop.schedule_generator.days" v-bind:key="dayIdx">
                {{ day.display_text }}
              </li>
            </ul>
            <p v-if="workshop.program.group_session_types.length == 1">
              Below you will find the upcoming {{ workshop.program.group_session_types[0].name }}s for this workshop.
            </p>
            <p v-else>
              Below you will find the upcoming sessions for this workshop.
            </p>
            <p v-if="workshop.program.individual_session_types.length == 1">
              In addition to the sessions below, this workshop also requires individual
              {{ workshop.program.individual_session_types[0].name }}s with the workshop staff.
            </p>
          </div>
          <table class="table">
            <tbody>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Time</th>
            </tr>
            <tr v-for="(session, sessionIdx) in workshop.schedule" v-bind:key="sessionIdx">
              <td>{{ session.start_datetime | moment("dddd - MMMM Do, YYYY") }}</td>
              <td>{{ session.start_datetime | moment('h:mmA') }} - {{ session.end_datetime | moment('h:mmA') }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import WorkshopLocation from "./WorkshopLocation"

export default {
  components: {
    WorkshopLocation,
  },
  props: {
    workshop: {
      required: true,
      type: Object
    },
    showEnroll: {
      required: false,
      type: Boolean,
      default: true,
    }
  }
}
</script>