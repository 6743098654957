<template>
  <div v-if="workshop.delivery_methods.length > 1" class="label label-success align-middle">Hybrid</div>
  <div v-else-if="workshop.delivery_methods[0] === 'self_directed'" class="label label-info align-middle">Self-Directed</div>
  <div v-else-if="workshop.delivery_methods[0] === 'in_person'" class="label label-warning align-middle">In-Person</div>
  <div v-else-if="workshop.delivery_methods[0] === 'virtual'" class="label label-primary align-middle">Live Online</div>
  <div v-else-if="workshop.delivery_methods[0] === 'telephonic'" class="label label-danger align-middle">Telephonic</div>
</template>

<script>
export default {
  props: {
    workshop: {
      type: Object,
      required: true
    }
  }
}
</script>