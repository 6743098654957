<template>
    <select class="form-control" v-model="internalValue" :id="id">
        <option :value="null">Narrow by Program:</option>
        <option v-for="program in programs" :key="programId(program)" :value="programId(program)">{{ program.name }}</option>
    </select>
</template>

<script>
    import {getPrograms} from "../../api/api";

    export default {

        props: {
            value: {
              default: null,
              required: true,
            },
            useGroups: {
              default: false,
              required: false,
            },
            id : {
                type: String,
                required: false,
            }
        },
        data() {
            return {
                programs: [],
                internalValue: this.value,
            }
        },

        watch: {
            'internalValue': function(newValue) {
                // When the internal value changes, we $emit an event. Because this event is
                // named 'input', v-model will automatically update the parent value
                this.$emit('input', newValue);
            }
        },
        created: function () {
            getPrograms().then((values) => {
                this.programs = values.data;
            })
        },
        methods: {
          programId: function(program) {
            if (program.groupId) {
              return 'group_' + program.groupId
            }

            return program.id;
          }
        }
    }

</script>