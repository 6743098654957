<template>
<div>
  <validation-provider :rules="{ required: true }" v-slot="{ errors, classes }">
    <div class="form-group required" :class="classes">
      <label class="b3-col-sm-3 b3-col-sm-3 control-label">
        Preferred Language
      </label>
      <div class="b3-col-sm-8">
        <select v-model="participant.language" class="form-control">
          <option :value="undefined" selected>Select a Language</option>
          <option v-for="option in languages" v-bind:key="option.id" :value="option.id">{{
              option.name
            }}
          </option>
        </select>
      </div>
      <div v-show="errors.length" class="help-block b3-col-sm-9 b3-col-sm-offset-3">This field is required.</div>
    </div>
  </validation-provider>
  <validation-provider :rules="{ required: true }" v-slot="{ errors, classes }">
    <div class="form-group required" :class="classes">
      <label class="b3-col-sm-3 b3-col-sm-3 control-label">
        How did you hear about this program?
      </label>
      <div class="b3-col-sm-8">
        <select v-model="participant.relationship" class="form-control">
          <option :value="undefined" selected>Please Select an Option:</option>
          <option v-for="option in referralOptions" v-bind:key="option.id" :value="option.id">{{
              option.name
            }}
          </option>
        </select>
      </div>
      <div v-show="errors.length" class="help-block b3-col-sm-9 b3-col-sm-offset-3">This field is required.</div>
    </div>
  </validation-provider>
  <validation-provider :rules="{ required: relationshipRequiresInput }" v-slot="{ errors, classes }"
                       v-if="relationshipRequiresInput">
    <div class="form-group required" :class="classes">
      <div class="b3-col-sm-5 b3-col-sm-offset-3">
        <div class="input-group" style="margin-top: 5px;">
          <span class="input-group-addon">Please Specify:</span>
          <input type="text" v-model="participant.relationship_input" class="form-control"/>
        </div>
      </div>
      <div v-show="errors.length" class="help-block b3-col-sm-9 b3-col-sm-offset-3">This field is required.</div>
    </div>
  </validation-provider>
</div>
</template>

<script>
import {getLanguages, getReferralOptions} from "../../api/api";

export default {
  props: {
    participant: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showTerms: false,
      languages: [],
      referralOptions: [],
    }
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    requireAddress() {
      if (typeof this.$compassOptions.require_address !== 'undefined') {
        return this.$compassOptions.require_address;
      }

      return false;
    },

    requireEmail() {
      return this.$requireEmail;
    },

    askAddress() {
      if (typeof this.$compassOptions.ask_address !== 'undefined') {
        return this.$compassOptions.ask_address;
      }

      return true;
    },

    relationshipRequiresInput() {
      if (!this.participant.relationship) {
        return;
      }

      return this.referralOptions.filter(option => option.id == this.participant.relationship).shift().require_input;
    }
  },
  methods: {
    fetchData() {
      return Promise.all([getLanguages(), getReferralOptions()]).then((values) => {
        this.languages = values[0].data;
        this.referralOptions = values[1].data;
      })
    }
  }
}
</script>