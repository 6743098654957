import Vue from 'vue'
import CompassApp from './App.vue'
import buildRouter from './router'
import axios from 'axios'

require('./validation')
require('./map.config')
require('./filters')

import './assets/sass/_bootstrap.scss'

import '../node_modules/nprogress/nprogress.css'

import { VueMaskDirective } from 'v-mask'

Vue.directive('mask', VueMaskDirective)
Vue.use(require('vue-moment'))
Vue.config.productionTip = false

window.onload = function() {
  loadCompassApp(window.compass_options);
}

function loadCompassApp(compass_options) {
  let historyMode = compass_options.history_mode || 'hash'
  const basePath = compass_options.base_path || ''
  let ownerKey = compass_options.owner_api_key || null
  let partnerKey = compass_options.partner_api_key || null
  let baseUrl = compass_options.base_url || 'https://www.compasshp.org/'

  if (typeof compass_options.require_email !== 'undefined') {
    Vue.prototype.$requireEmail = compass_options.require_email
  } else {
    Vue.prototype.$requireEmail = true
  }


  if (!baseUrl.endsWith('/')) {
    baseUrl += '/'
  }

  axios.defaults.baseURL = baseUrl

  let headers = {}

  let ownerArgumentSet = false;

  if (ownerKey) {
    ownerArgumentSet = true;
    headers['X-Owner-Key'] = ownerKey;
  }

  if (partnerKey) {
    ownerArgumentSet = true;
    headers['X-Partner-Key'] = partnerKey;
  }

  axios.defaults.headers.common = headers;

  Vue.mixin({
    data: function () {
      return {
        ownerArgumentSet: ownerArgumentSet,
      }
    }
  })

  const router = buildRouter(historyMode, basePath)

  // provide options to all Vue Components
  Vue.prototype.$compassOptions = compass_options;

  new Vue({
    router,
    render: h => h(CompassApp)
  }).$mount("#compass-app");
}
